import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import CardLayout from "../components/cardLayout"
import pencil from "../images/pencil.svg"
import styles from "./petition.module.css"
import ExternalLink from "../components/externalLink"

const local = [
  {
    name:
      "Demands for Livingston Police Department and Livingston Public Schools",
    href:
      "https://docs.google.com/forms/d/e/1FAIpQLSfzwDZ2DhQlr5w69fyIBegL9tO4S-2a8Toj0LXsHHpqt5VEAQ/viewform",
  },
  {
    name: "Justice for Amani Kildea, Morristown, NJ",
    href:
      "https://www.change.org/p/morristown-police-department-justice-for-amani-kildea",
  },
  {
    name: "Demand Proactive Police Reform in West Orange, NJ",
    href:
      "https://www.change.org/p/west-orange-town-council-demand-police-reform-in-west-orange-nj-5cb819e0-d381-413e-8587-e63d5f426ab6",
  },
  {
    name:
      "Replace the statue of Christopher Colombus with a statue of Marsha P. Johnson",
    href:
      "https://www.change.org/p/city-of-elizabeth-replace-the-statue-of-christopher-colombus-with-a-statue-of-marsha-p-johnson",
  },
]

const national = [
  {
    name: "Black Lives Matter: Defund the Police",
    href: "https://blacklivesmatter.com/defundthepolice/",
  },
  {
    name: "Justice for Breonna Taylor",
    href: "https://www.change.org/p/andy-beshear-justice-for-breonna-taylor",
  },
  { name: "For Breonna Taylor", href: "https://www.forbreonna.com/" },
  {
    name: "Breonna was Essential",
    href: "https://action.justiceforbreonna.org/sign/BreonnaWasEssential/",
  },
  {
    name: "Demand Racial Data on Coronavirus",
    href: "https://blacklivesmatter.com/demand-racial-data-on-coronavirus/",
  },
  {
    name: "Coronavirus: Demand More from the Government",
    href:
      "https://blacklivesmatter.com/coronavirus-demand-more-from-the-government/",
  },
  { name: "NAACP LDF: Legal Defense Fund", href: "https://www.naacpldf.org/" },
]

const Petition = () => (
  <Layout>
    <Card>
      <CardLayout headerImageSrc={pencil}>
        <h1>Sign Petitions</h1>

        <p>
          Signing a petition is a way for you to participate in the present and
          change the future. Here are a local and national petitions for you to
          sign
        </p>

        <div className={styles.linksContainer}>
          <div className={styles.column}>
            <h3>Local</h3>
            <ul>
              {local.map(({ name, href }) => (
                <li>
                  <ExternalLink to={href}>{name}</ExternalLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.column}>
            <h3>National</h3>
            <ul>
              {national.map(({ name, href }) => (
                <li>
                  <ExternalLink to={href}>{name}</ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CardLayout>
    </Card>
  </Layout>
)

export default Petition
